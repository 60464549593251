import * as React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';

const NotFoundPage = (): JSX.Element => {
    return (
        <>
            <Helmet title="404 Page not found" />
            <Layout>
                <div className="page">Sorry, page not found.</div>
            </Layout>
        </>
    );
};

export default NotFoundPage;
